import { useFormikContext } from "formik";
import { useTranslation } from "next-i18next";

import { PlanCreateFormValues } from "./plan-create.form-schema";
import { CustomerSelectOneCellField } from "../../../customer/formik/CustomerSelectOneCellField";
import { FormTable } from "../../../ui-atoms/components";
import { LabelCell } from "../../../ui-atoms/components/form-table/LabelCell";
import { CellInputs } from "../../../ui-form/components";
import { RadioGroupField } from "../../../ui-form/components/Formik/RadioGroupField";
import { UserSelectOneCellField } from "../../../user/formik/UserSelectOneCellField";

/** The body of the "table-form" when creating a plan */
export function TableBody() {
	const { t } = useTranslation();
	const { values } = useFormikContext<PlanCreateFormValues>();

	return (
		<tbody>
			<tr data-testid="plan/modal/create/row/type">
				<LabelCell required>{t("features.plan.plan-type")}</LabelCell>
				<FormTable.UnfocusableTableCell colSpan={2}>
					<RadioGroupField<PlanCreateFormValues, "type">
						name="type"
						radios={[
							{
								label: t("features.plan.plan-types.alone"),
								value: "1",
							},
							{
								label: t("features.plan.plan-types.couple"),
								value: "2",
							},
						]}
					/>
				</FormTable.UnfocusableTableCell>
			</tr>

			<tr data-testid="plan/modal/create/row/customers">
				<LabelCell required>
					{t("entity.plan._list.customers")}
				</LabelCell>

				<CustomerSelectOneCellField<PlanCreateFormValues, true>
					colSpan={values.type === "1" ? 2 : 1}
					name="fkCustomer"
					select={{ disableClearable: true }}
				/>

				{values.type === "2" && (
					<CustomerSelectOneCellField<PlanCreateFormValues>
						name="fkCustomer2"
						select={{
							// Or override query
							getOptionDisabled: ({ _id }) =>
								_id === values.fkCustomer,
						}}
					/>
				)}
			</tr>

			<tr data-testid="plan/modal/create/row/year">
				<LabelCell required>{t("entity.plan.year_long")}</LabelCell>

				<CellInputs.FieldInput<PlanCreateFormValues>
					colSpan={2}
					name="year"
					type="number"
				/>
			</tr>

			<tr data-testid="plan/modal/create/row/observation">
				<LabelCell required>
					{t("entity.plan.observation_long")}
				</LabelCell>

				<CellInputs.FieldInput<PlanCreateFormValues>
					colSpan={2}
					input={{
						endDecorator: t("common.date.time.years"),
					}}
					name="observationTime"
					type="number"
				/>
			</tr>

			<tr data-testid="plan/modal/create/row/counselor">
				<LabelCell required>{t("entity.customer.counselor")}</LabelCell>
				<UserSelectOneCellField<PlanCreateFormValues>
					colSpan={2}
					name="fkCounselor"
				/>
			</tr>
		</tbody>
	);
}
